export enum NotificationTargetType {
  User = 'user',
  VendorUser = 'vendorUser',
  All = 'all',
  Group = 'group',
  VendorGroup = 'vendorGroup',
}

export const NotificationTargetTypeList = [
  { id: NotificationTargetType.User, name: 'Пользователь' },
  { id: NotificationTargetType.VendorUser, name: 'Вендор' },
  { id: NotificationTargetType.Group, name: 'Группа пользователей' },
  { id: NotificationTargetType.VendorGroup, name: 'Группа вендоров' },
  { id: NotificationTargetType.All, name: 'Все' },
]
