import * as React from 'react'
import { FC } from 'react'
import { Datagrid, DateField, Filter, List, ListProps, TextField } from 'react-admin'
import EmptyList from 'src/components/list/EmptyList'
import PriceField from 'src/components/fields/PriceField'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import PartnerInput from 'src/components/inputs/PartnerInput'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import AdminLinkField from 'src/components/fields/AdminLinkField'

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <PartnerInput source={'partnerId'} label={'Партнер'} alwaysOn />
      <RestaurantInput source={'restaurantId'} label={'Ресторан'} serviceType={ServiceType.Coffee} alwaysOn />
    </Filter>
  )
}

const CoffeeShiftReceiptList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Чеки за смену"
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
      hasCreate={false}
      empty={<EmptyList title={'Список чеков пуст'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Кофейня'} />
        <AdminLinkField mainSource={'admin'} label={'Менеджер'} />
        <PriceField source={'total'} label={'Сумма'} />
        <PriceField source={'commission'} label={'Комиссия'} />
        <DateField source="shiftDate" label={'Дата смены'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </Datagrid>
    </List>
  )
}

export default CoffeeShiftReceiptList
