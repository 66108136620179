import slugify from 'slugify'
const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
import { formatRelative } from 'date-fns'
import { ru } from 'date-fns/locale'
import { IUserAddress } from 'src/types/interfaces/IUserAddress'

const pluralizeNative = require('numeralize-ru').pluralize

export default class Formatter {
  static pluralize(number: number, word1: string, word2: string, word3: string) {
    return pluralizeNative(number, word1, word2, word3)
  }

  static slug = (val: string) =>
    val
      ? slugify(val)
          ?.replace(/[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')
          ?.toLowerCase()
          ?.trim() || ''
      : ''

  static cleanPhone = (phone: string) => {
    if (phone) {
      let phoneCleaned = phone.replace(/[^\+0-9]/g, '')
      if (!phoneCleaned.startsWith('+')) {
        phoneCleaned = '+' + phoneCleaned
      }
      return phoneCleaned
    }
    return phone
  }

  static formatDateRelative(date: string | Date) {
    const formatRelativeLocale: { [key: string]: string } = {
      yesterday: 'Вчера в HH:mm',
      today: 'Сегодня в HH:mm',
      other: 'dd MMMM yyyy HH:mm', // Difference: Add time to the date
    }

    const locale = {
      ...ru,
      formatRelative: (token: string) => formatRelativeLocale[token] || formatRelativeLocale['other'],
    }
    if (!date) {
      return ''
    }
    return formatRelative(typeof date === 'string' ? new Date(date) : date, new Date(), { locale })
  }

  static formatDateUntil(date: string | Date) {
    const formatRelativeLocale: { [key: string]: string } = {
      yesterday: 'Вчера до HH:mm',
      today: 'до HH:mm',
      tomorrow: 'Завтра до HH:mm',
      other: 'до dd.MM.yyyy HH:mm', // Difference: Add time to the date
    }

    const locale = {
      ...ru,
      formatRelative: (token: string) => formatRelativeLocale[token] || formatRelativeLocale['other'],
    }
    if (!date) {
      return ''
    }
    return formatRelative(typeof date === 'string' ? new Date(date) : date, new Date(), { locale })
  }

  static pad(pad: string, str: string, padLeft = true) {
    if (typeof str === 'undefined') return pad
    if (padLeft) {
      return (pad + str).slice(-pad.length)
    } else {
      return (str + pad).substring(0, pad.length)
    }
  }

  static formatNumber(num: number, separator?: string) {
    return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  static formatPrice = (price: number) => {
    if (price === 0) {
      return `${price} ₽`
    }
    if (!price) {
      return ''
    }
    return `${this.formatNumber(Math.ceil(price))} ₽`
  }

  static formatPhone(phone: string) {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, 'RU')
      return phoneUtil.format(number, PNF.INTERNATIONAL)
    } catch (e) {
      return phone
    }
  }

  static formatDataWithTranslation = ({
    fields,
    data,
    langCode,
  }: {
    fields: string[]
    data: any
    langCode: string
  }) => {
    const translations = data.translations || []
    let currentTranslationIndex = translations.findIndex((i: any) => i.languageCode === langCode)
    if (currentTranslationIndex === -1) {
      currentTranslationIndex = 0
      translations.push({ languageCode: langCode })
    }
    const newData = { ...data }
    for (const i of fields) {
      translations[currentTranslationIndex][i] = newData[i] || ''

      delete newData[i]
    }
    return { ...newData, translations }
  }

  static formatUserName(record: any) {
    return this.formatUserFirstLastName(record)
  }

  static formatUserFirstLastName(record: any) {
    return `${record?.firstName || record?.lastName ? ` ${record?.lastName || ''}${record?.firstName ? ` ${record?.firstName}` : ''}` : ''}`
  }

  static formatAddress(address: IUserAddress) {
    if (!address) {
      return ''
    }
    const parts = [
      `${address.entrance ? `${address.entrance} п.` : ''}`,
      `${address.floor ? `${address.floor} эт.` : ''}`,
      `${address.apartment ? `${address.apartment} кв.` : ''}`,
      `${address.intercomCode ? `Домофон: ${address.intercomCode}` : ''}`,
    ]
    return `${address.address} ${parts.filter((i) => !!i).join(',')}`
  }

  static formatJson(json: string) {
    if (!json) {
      return
    }
    try {
      return JSON.parse(json)
    } catch (e) {
      return null
    }
  }

  static formatSize(bytes: number) {
    const sufixes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return (!bytes && '0 байт') || (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i]
  }
}
