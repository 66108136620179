export enum ComplaintReason {
  LateOrder = 'lateOrder', //Задержали заказ
  MessedOrder = 'messedOrder', // Перепутали заказ
  Dish = 'dish', // Жалоба на блюдо
  Order = 'order', // Жалоба на заказ
  NotDelivered = 'notDelivered',
  Poisoning = 'poisoning',
  ForeignObject = 'foreignObject',
  CourierComplaint = 'courierComplaint',
  IncompleteDish = 'incompleteDish',
  Cold = 'cold',
  NotMarketable = 'notMarketable',
  OperatorComplaint = 'operatorComplaint',
  ProductOnStop = 'productOnStop',
  TechError = 'techError',
  Flyers = 'flyers',
  Oon = 'oon',
}

export const ComplaintReasonList = [
  { id: ComplaintReason.LateOrder, name: 'Задержали заказ' },
  { id: ComplaintReason.MessedOrder, name: 'Перепутали заказ' },
  { id: ComplaintReason.Dish, name: 'Жалоба на блюдо' },
  { id: ComplaintReason.Order, name: 'Жалоба на заказ' },
  { id: ComplaintReason.NotDelivered, name: 'Не доставили заказ' },
  { id: ComplaintReason.Poisoning, name: 'Отравление' },
  { id: ComplaintReason.ForeignObject, name: 'Инородный предмет' },
  { id: ComplaintReason.CourierComplaint, name: 'Жалоба на курьера' },
  { id: ComplaintReason.IncompleteDish, name: 'Недокомплект блюда' },
  { id: ComplaintReason.Cold, name: 'Холодное' },
  { id: ComplaintReason.NotMarketable, name: 'Не товарный вид' },
  { id: ComplaintReason.OperatorComplaint, name: 'Жалоба на оператора' },
  { id: ComplaintReason.ProductOnStop, name: 'Продукт на стопе' },
  { id: ComplaintReason.TechError, name: 'Тех ошибка' },
  { id: ComplaintReason.Flyers, name: 'Листовки' },
  { id: ComplaintReason.Oon, name: 'ООН' },
]
