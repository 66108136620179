import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import PercentField from 'src/components/fields/PercentField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceType } from 'src/types/enum/ServiceType'

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn />
      <RestaurantInput
        serviceType={ServiceType.Restaurants}
        alwaysOn={true}
        resettable={true}
        source="restaurantId"
        label={'Ресторан'}
      />
    </Filter>
  )
}

const BanquetOfferList: FC<ListProps> = (props) => {
  return (
    <List {...props} filters={<_Filter />} title="Предложения" exporter={false} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <IdShowButtonField source={'id'} label={'ID'} type={'show'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </Datagrid>
    </List>
  )
}

export default BanquetOfferList
