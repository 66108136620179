import * as React from 'react'
import { FC, useMemo } from 'react'
import {
  Filter,
  DateField,
  List,
  SelectArrayInput,
  BooleanInput,
  Pagination,
  ListProps,
  TextField,
  SelectField,
  TextInput,
  useResourceContext,
} from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PriceField from 'src/components/fields/PriceField'
import { PaymentStatusList } from 'src/types/enum/PaymentStatus'
import {
  OrderStatusesActive,
  OrderStatusFinishedList,
  OrderStatusList,
  OrderStatusUnfinishedList,
} from 'src/types/enum/OrderStatus'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import PartnerArrayInput from 'src/components/inputs/PartnerArrayInput'
import BrandArrayInput from 'src/components/inputs/BrandArrayInput'
import UnitArrayInput from 'src/components/inputs/UnitArrayInput'
import RegionArrayInput from 'src/components/inputs/RegionArrayInput'
import { DeliveryMethodList } from 'src/types/enum/DeliveryMethod'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { AgGridReact } from 'ag-grid-react'
import { Datagrid } from 'src/components/list/Datagrid'
import { colors } from '../../style'
import moment from 'moment'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      id: 'id',
      number: 'Номер',
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Order',
  })
}

const _Filter = (props: any) => {
  const resource = useResourceContext()
  const statusList = useMemo(() => {
    switch (resource) {
      case 'order-active':
      case 'order-finished':
      case 'order-confirmed':
        return OrderStatusFinishedList
      case 'order-cancelled':
      case 'order-refunding':
        return OrderStatusUnfinishedList
      default:
        return OrderStatusList
    }
  }, [resource])

  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
      <TextInput
        source="number:$endsL&phone:$contL&partnerNumber:$endsL"
        label={'Поиск'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
      <SelectArrayInput source="status:$in" label={'Статус'} choices={statusList} />
      <SelectArrayInput source="paymentStatus" label={'Статусы оплаты'} choices={PaymentStatusList} />
      <SelectArrayInput source="paymentMethod" label={'Оплата'} choices={PaymentMethodList} />
      <SelectArrayInput source="deliveryMethod" label={'Доставка'} choices={DeliveryMethodList} />
      <RegionArrayInput source={'unit.regionId:$in'} label={'Регионы'} />
      <PartnerArrayInput source={'partnerId:$in'} label={'Партнеры'} />
      <BrandArrayInput source={'brandId:$in'} label={'Бренды'} />
      <UnitArrayInput source={'unitId:$in'} label={'Заведения'} alwaysOn={true} />
      <BooleanInput source={'groupLate'} label={'С опозданием'} />
      <BooleanInput source={'isPreOrder'} label={'Ко времени'} />
    </Filter>
  )
}

export const DataGridRowProps = {
  rowStyle: (record: any) => {
    return {
      ...(OrderStatusesActive.includes(record.status) &&
      moment(record.updatedAt).add({ minutes: 5 }).isBefore(new Date())
        ? { backgroundColor: colors.rowBgRed, color: colors.rowColorRed }
        : {}),
    }
  },
}

const DataGridRaw = () => {
  return (
    <Datagrid bulkActionButtons={false} rowClick={'show'} data-cy="orders-table" {...DataGridRowProps}>
      <TextField type={'show'} source={'number'} label={'Номер'} sx={{ whiteSpace: 'nowrap' }} />
      <SelectField source={'status'} label={'Статус'} choices={OrderStatusList} />
      <SelectField source={'paymentStatus'} label={'Статус оплаты'} choices={PaymentStatusList} />
      <SelectField source={'paymentMethod'} label={'Тип оплаты'} choices={PaymentMethodList} />
      <PriceField source={'total'} label={'Сумма'} />
      <PhoneCallActionField source={'phone'} label={'Телефон'} />
      <TextField source={'name'} label={'Имя'} />
      <DateField source="createdAt" label={'Создан'} showTime={true} />
      <TextField source={'partner.name'} label={'Партнер'} />
      <TextField source={'brand.name'} label={'Бренд'} />
      <TextField source={'unit.name'} label={'Заведение'} />
    </Datagrid>
  )
}
const OrderList: FC<ListProps> = (props) => {
  const columnDefs = [
    { field: 'id' },
    { field: 'number' },
    { field: 'status' },
    { field: 'paymentStatus' },
    { field: 'paymentMethod' },
    { field: 'phone' },
    { field: 'name' },
    { field: 'createdAt' },
    { field: 'partner.name' },
    { field: 'brand.name' },
    { field: 'unit.name' },
  ]
  const gridRef = React.useRef<AgGridReact>(null)
  const onFirstDataRendered = React.useCallback(() => {
    //  gridRef.current?.api.autoSizeAllColumns()
  }, [])
  const defaultColDef = {
    //   flex: 1
  }
  return (
    <List
      {...props}
      filters={<_Filter />}
      pagination={<Pagination />}
      title="Заказы"
      hasCreate={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Пока нет заказов'} />}
    >
      <DataGridRaw />
    </List>
  )
}

export default OrderList
