import * as React from 'react'
import { useState } from 'react'
import * as _ from 'lodash'
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  validate?: any
  fullWidth?: boolean
  alwaysOn?: boolean
  filter?: any
  optionText?: (value: any) => string
  onChange?: (ids: number[]) => void
}

const UnitArrayInput = (props: Props) => {
  const [searchText, setSearchText] = useState('')
  //
  return (
    <ReferenceArrayInput
      alwaysOn={props.alwaysOn}
      allowEmpty={false}
      source={props.source}
      reference="unit"
      variant={'outlined'}
      fullWidth={true}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      enableGetChoices={({ q }) => q && q.length >= 2}
      perPage={100}
    >
      <AutocompleteArrayInput
        validate={props.validate}
        label={props.label}
        noOptionsText="-"
        filterToQuery={() => ({ 'name:$contL&brand.name:$contL': searchText })}
        source="id"
        optionText={
          _.isFunction(props.optionText) ? props.optionText : (i) => (i ? `${i?.brand?.name} - ${i?.name}` : '')
        }
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        onInputChange={(e) => {
          const value = (e.target as any).value

          if (_.isNumber(value)) {
            return
          }
          setSearchText((e.target as any).value)
        }}
        disableCloseOnSelect
      />
    </ReferenceArrayInput>
  )
}

export default UnitArrayInput
