import React from 'react'
import { useRecordContext } from 'ra-core'
import { Typography } from '@mui/material'
import { FieldProps, RaRecord } from 'react-admin'
import { NotificationChannelList } from 'src/types/enum/NotificationChannel'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const ChannelsField = (props: Props) => {
  const record = useRecordContext()
  const values = record.channels
    .filter((item: any) => NotificationChannelList.find((i) => i.id === item))
    .map((item: any) => item)

  return <Typography variant={'body2'}>{values.join(',')}</Typography>
}
export default ChannelsField
