export enum PromoReserveStatus {
  Created = 'created',
  Approved = 'approved',
  Confirmation = 'confirmation',
  Cancelled = 'cancelled',
  WaitingForFillingTotalPrice = 'waitingForFillingTotalPrice',
  FilledTotalPrice = 'filledTotalPrice',
}

export const PromoReserveStatusList = [
  { id: PromoReserveStatus.Created, name: 'Создан' },
  { id: PromoReserveStatus.Confirmation, name: 'Подтверждается' },
  { id: PromoReserveStatus.Approved, name: 'Подтверждена' },
  { id: PromoReserveStatus.Cancelled, name: 'Отменена' },
  { id: PromoReserveStatus.WaitingForFillingTotalPrice, name: 'Не указана сумма' },
  { id: PromoReserveStatus.FilledTotalPrice, name: 'Завершена' },
]
