import * as React from 'react'
import * as _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import { Button, Typography } from '@mui/material'
import { required, TextInput } from 'react-admin'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import { PromoKind } from 'src/types/enum/PromoKind'
import { useRecordContext } from 'ra-core'
import Checkbox from '@mui/material/Checkbox'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import { PromoGroup } from 'src/types/enum/PromoGroup'

type PromoCodeProps = {
  generatePromoCodeFlag: boolean
  onGeneratePromoCode: () => string
  onDeletePromoCode: () => void
  isCreation: boolean
}

export const PromoCodeGenerate = ({
  generatePromoCodeFlag,
  onGeneratePromoCode,
  onDeletePromoCode,
  isCreation,
}: PromoCodeProps) => {
  const record = useRecordContext()
  const form = useFormContext()

  const watch = form.watch()

  useEffect(() => {
    if (!_.isBoolean(watch.sendPromoCodeSmsDto)) {
      form.setValue('sendPromoCodeSmsDto', true)
    }
  }, [])

  const generatePromoCode = () => {
    const promoCode = onGeneratePromoCode()
    //
    form.setValue('promoCodeDto', promoCode)
  }

  const deletePromoCode = () => {
    onDeletePromoCode()
    //
    form.setValue('promoCodeDto', null)
    form.setValue('promoIdDto', null)
  }

  const onToggleSendSmsCheckbox = () => {
    form.setValue('sendPromoCodeSmsDto', _.isBoolean(watch.sendPromoCodeSmsDto) ? !watch.sendPromoCodeSmsDto : true)
  }

  if (!isCreation && record?.promoCode) {
    return null
  }
  if (generatePromoCodeFlag) {
    return (
      <div>
        <Typography variant={'subtitle1'}>Промокод</Typography>
        <TextInput source="promoCodeDto" label={'Промокод'} fullWidth validate={required()} />
        <PromoServiceDeliveryInput
          source="promoIdDto"
          label={'Акция'}
          filter={{ kind: PromoKind.Personal, group: PromoGroup.ManagerSorry }}
          fullWidth
          validate={required()}
        />
        <Box mb={0.3}>
          <Button variant="text" size={'small'} color="primary" onClick={onToggleSendSmsCheckbox}>
            Отправить смс клиенту
          </Button>
          <Checkbox
            value={watch.sendPromoCodeSmsDto}
            checked={watch.sendPromoCodeSmsDto}
            onChange={onToggleSendSmsCheckbox}
            title={'Send sms'}
          />
        </Box>
        <Button variant="contained" size={'small'} color="primary" onClick={deletePromoCode}>
          Удалить промокод
        </Button>
      </div>
    )
  }
  return (
    <div>
      <Button variant="contained" size={'small'} color="primary" onClick={generatePromoCode}>
        Сгенерировать промокод
      </Button>
    </div>
  )
}
