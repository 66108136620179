import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField, BooleanField, TextInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { AlertTypeItemList } from 'src/types/enum/AlertType'
import { ApplicationItemList } from 'src/types/enum/Application'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn resettable />
  </Filter>
)

const AlertList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Алерты"
      empty={<EmptyList title={'Нет алертов'} description={'Вы можете добавить новый алерт'} buttonText={'Добавить'} />}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Название'} />
        <TextField source="title" label={'Заголовок'} />
        <SelectField source={'application'} label={'Приложение'} choices={ApplicationItemList} />
        <SelectField source={'type'} label={'Тип'} choices={AlertTypeItemList} />
        <TextField source={'region.name'} label={'Город'} />
        <BooleanField source={'active'} label={'Активен'} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default AlertList
