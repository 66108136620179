import * as React from 'react'
import * as _ from 'lodash'
import { NumberInput, RecordContextProvider, required, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { useTotalContext } from 'src/context/total_state'
import { useMemo } from 'react'

interface Props {
  id?: number
  isRefillMode?: boolean
  onClose?: () => void
}

export const PromoReserveFillTotalPriceModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const totalContext = useTotalContext()

  const isRefillMode = useMemo(() => _.isBoolean(props.isRefillMode) && props.isRefillMode, [props.isRefillMode])

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.update(`promo-reserve/${isRefillMode ? 'refill' : 'fill'}`, {
      id: record.id,
      data,
      previousData: record,
    })

    if (props.onClose) {
      props.onClose()
    }
    refresh()
    totalContext.fetchPromoReserve()
    //
    return true
  }

  const validate = (value: number): string | null => {
    if (value < 0) {
      return 'Сумма чека не может быть отрицательной'
    }
    return null
  }

  return (
    <RecordContextProvider>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'promo-reserve'}
        title={isRefillMode ? 'Изменить сумму чека' : 'Завершить бронь'}
        onClose={props.onClose}
        saveButtonLabel={isRefillMode ? 'Сохранить' : 'Завершить'}
        cancelButtonLabel={'Назад'}
        save={handleSubmit}
      >
        <NumberInput
          name={'total'}
          source={'total'}
          label={'Сумма чека'}
          helperText={'Укажите сумму чека'}
          validate={[required(), validate]}
        />
      </ModalForm>
    </RecordContextProvider>
  )
}
