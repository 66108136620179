import * as React from 'react'
import { SimpleForm, TextInput, required, SelectInput, BooleanInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import { PriceRatingList } from 'src/types/enum/PriceRating'
import { MediaInput } from 'src/components/inputs/MediaInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import Formatter from 'src/utils/formatters'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'

const InfoTab = (props: any) => {
  const form = useFormContext()

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }

  return (
    <>
      <FormGroup title={'Юридические данные'}>
        <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
        <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
        <IfCanAccess aggregator action={'edit'}>
          <PartnerInput validate={[required()]} />
        </IfCanAccess>
        <BooleanInput source="published" label={'Активен'} fullWidth />

        <SelectInput
          source="priceRating"
          label={'Сегмент'}
          choices={PriceRatingList}
          validate={[required()]}
          fullWidth
        />
        <MediaInput source="logo" label={'Логотип'} fullWidth />
        <MediaInput source="image" label={'Обложка'} fullWidth />

        <TextInput source="description" multiline label={'Описание'} fullWidth />
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput source="phone" label={'Номер телефона'} fullWidth />
      </FormGroup>
    </>
  )
}
const BrandForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <InfoTab />
    </SimpleForm>
  )
}
export default BrandForm
