import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const BrandLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/brand/${get(record, props.source ?? 'brand.id')}`}
      source={props.source ?? 'brand.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'brand')?.name : record?.brand?.name)}
      label={props.label}
    />
  )
}

export default BrandLinkField
