import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  withPhone?: boolean
  sourcePhone?: string
}

const UnitLinkField = (props: Props) => {
  return (
    <LinkField
      link={({ unit }) => `/unit/${unit?.id}`}
      source={props.source ?? 'unit.name'}
      value={({ unit }) => [unit?.name].filter((i) => !!i).join(' - ')}
      label={props.label}
      icon={
        props.withPhone ? <PhoneCallActionField source={props.sourcePhone ?? 'unit.phone'} onlyIcon={true} /> : null
      }
    />
  )
}

export default UnitLinkField
