import * as React from 'react'
import { FC, useMemo } from 'react'
import * as _ from 'lodash'
import {
  Filter,
  List,
  ListProps,
  SelectField,
  DateField,
  ReferenceField,
  FunctionField,
  TextField,
  FieldProps,
} from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import ChannelsField from 'src/resources/notification/components/ChannelsField'
import { NotificationTypeList } from 'src/types/enum/NotificationType'
import { NotificationTargetType, NotificationTargetTypeList } from 'src/types/enum/NotificationTargetType'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { useRecordContext } from 'ra-core'
import styles from 'src/components/RecordChangeList/index.module.scss'
import Typography from '@mui/material/Typography'
import { ChipProps } from '@mui/material/Chip'
import {
  NotificationAttemptStatusesListWithPriority,
  NotificationAttemptStatusList,
} from 'src/types/enum/NotificationAttemptStatus'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Notification',
  })
}

interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

const NotificationSentToField = (props: Props) => {
  const record = useRecordContext(props)

  const data = useMemo((): string => {
    if (record.targetType === NotificationTargetType.User) {
      return record.user?.phone ?? record.data?.phone ?? `ID ${record.user?.id}`
    }
    if (
      record.targetType === NotificationTargetType.Group ||
      record.targetType === NotificationTargetType.VendorGroup
    ) {
      if (_.isArray(record.data?.emails)) {
        return _.uniq(record.data?.emails).join(', ')
      }
      return `ID ${(record.data?.userIds as string[])?.join(', ')}`
    }
    return 'Unknown'
  }, [record])

  return (
    <div className={styles.root}>
      <Typography component="div" variant="body2">
        {data}
      </Typography>
    </div>
  )
}

const NotificationStatusField = (props: Props) => {
  const record = useRecordContext(props)

  const data = useMemo((): string => {
    if (_.isArray(record.attempts)) {
      const recentStatus = NotificationAttemptStatusesListWithPriority.find((status) =>
        record.attempts.find((attempt: any) => attempt.status === status),
      )

      if (recentStatus) {
        const attempt = record.attempts.find((el) => el.status === recentStatus)
        //
        return NotificationAttemptStatusList.find((el) => el.id === attempt?.status)?.name || 'Unknown'
      }
    }
    return 'Unknown'
  }, [record])

  return (
    <div className={styles.root}>
      <Typography component="div" variant="body2">
        {data}
      </Typography>
    </div>
  )
}

const _Filter = (props: any) => {
  return <Filter {...props}></Filter>
}

const NotificationList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Уведомления"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField type={'show'} source={'id'} label={'ID'} />
        <SelectField source="type" choices={NotificationTypeList} label={'Тип'} />
        <SelectField source="targetType" choices={NotificationTargetTypeList} label={'Кому'} />
        <NotificationSentToField label={'Отправить на'} />
        <NotificationStatusField label={'Статус'} />
        <ChannelsField label={'Каналы'} />
        <DateField source={'scheduleSentAt'} label={'Отправить в'} showTime={true} />
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <IfCanAccess label="Клиент" source="userId" aggregator action={'show'}>
          <ReferenceField label="Клиент" source="userId" reference="user">
            <FunctionField render={(i: any) => `#${i.id} ${i.defaultProfile?.name || ''}`} />
          </ReferenceField>
        </IfCanAccess>
      </Datagrid>
    </List>
  )
}

export default NotificationList
