import { AutocompleteInput, ReferenceInput } from 'react-admin'
import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { omit } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  partnerId?: number
  validate?: any
  onChange?: (val: any) => void
  fullWidth?: boolean
}

const UserInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="userId"
      reference="user"
      variant={'outlined'}
      enableGetChoices={({ q }) => q && q.length >= 2}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={30}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'phone:$contL': q.replaceAll(/[- ]/gim, '') })}
        optionText={(i) => `${Formatter.formatPhone(i.phone)}${i?.name ? ` ${i.name}` : ''}`}
        label="Клиент"
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        validate={props.validate}
      />
    </ReferenceInput>
  )
}

export default UserInput
