import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  fullWidth?: boolean
  filter?: FilterPayload
  validate?: any
  onChange?: (val: any) => void
}

const BrandInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source ?? 'brandId'}
      reference="brand"
      variant={'outlined'}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL&partner.name:$contL': q })}
        optionText={(i) => `${i?.name ? `${i.name} ${i.partner?.name ? ` - ${i.partner?.name}` : ''}` : ''}`}
        label="Бренд"
        fullWidth={props.fullWidth}
        onChange={props.onChange}
      />
    </ReferenceInput>
  )
}

export default BrandInput
