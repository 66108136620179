import * as React from 'react'
import { FieldProps } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props extends FieldProps {
  mainSource?: string
}

const AdminLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/admin/${get(record, props.source ?? 'admin.id')}`}
      source={props.source ?? 'admin.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'admin')?.name : record?.user?.name)}
      label={props.label}
    />
  )
}

export default AdminLinkField
