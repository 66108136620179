import * as React from 'react'
import { SimpleForm, required, SelectInput, TextInput, Toolbar, SaveButton, BooleanInput } from 'react-admin'
import { ApplicationItemList } from 'src/types/enum/Application'
import { AlertTypeItemList } from 'src/types/enum/AlertType'
import RegionInput from 'src/components/inputs/ReqionInput'

export const AlertForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      toolbar={
        <Toolbar {...props}>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput
        source={'name'}
        label={'Название алерта (для идентификации в админке)'}
        fullWidth
        validate={required()}
      />
      <TextInput source={'title'} label={'Заголовок'} fullWidth />
      <TextInput source={'description'} label={'Текст'} fullWidth />
      <SelectInput
        source={'application'}
        label={'Приложение'}
        choices={ApplicationItemList}
        fullWidth
        disabled={props.isEditMode}
        validate={required()}
      />
      <SelectInput source={'type'} label={'Тип алерта'} choices={AlertTypeItemList} fullWidth validate={required()} />
      <RegionInput source={'regionId'} label={'Город'} allowEmpty={false} fullWidth validate={required()} />
      <BooleanInput source={'active'} label={'Активен'} validate={required()} />
    </SimpleForm>
  )
}
