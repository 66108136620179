export enum NotificationType {
  admin = 'admin',
  userRegistered = 'userRegistered',
  chatMessage = 'chatMessage',
  preOrderCreated = 'preOrderCreated',
  orderCreated = 'orderCreated',
  orderCancelled = 'orderCancelled',
  orderPreparing = 'orderPreparing',
  orderDelivering = 'orderDelivering',
  orderDelivered = 'orderDelivered',
  orderFeedbackRemind = 'orderFeedbackRemind',
  authPinCode = 'authPinCode',
  promoCode = 'promoCode',
  newComplaint = 'newComplaint',
  newPromoQrCode = 'newPromoQrCode',
  notifyAboutNewPromoRestaurant = 'notifyAboutNewPromoRestaurant',
  promoQrCodeCreated = 'promoQrCodeCreated',
  promoQrCodeConfirmation = 'promoQrCodeConfirmation',
  promoReserveStartsIn10MinutesVendor = 'promoReserveStartsIn10MinutesVendor',
  promoReserveExpiresIn15Minutes = 'promoReserveExpiresIn15Minutes',
  promoReserveExpired = 'promoReserveExpired',
  promoReserveExpiredVendor = 'promoReserveExpiredVendor',
  promoQrCodeApproved = 'promoQrCodeApproved',
  promoQrCodeCancelled = 'promoQrCodeCancelled',
  promoReserveCancelledVendor = 'promoReserveCancelledVendor',
  subscriptionActivated = 'subscriptionActivated',
  banquetCreatedVendor = 'banquetCreatedVendor',
  banquetCreatedAbstractVendor = 'banquetCreatedAbstractVendor',
  banquetAddedToFavorites = 'banquetAddedToFavorites',
  banquetStopped = 'banquetStopped',
  fakeBanquetStoppedVendor = 'fakeBanquetStoppedVendor',
  banquetOfferReserved = 'banquetOfferReserved',
  newBanquetOfferSent = 'newBanquetOfferSent',
  banquetOfferReminder = 'banquetOfferReminder',
  banquetReserved = 'banquetReserved',
  banquetOfferCancelled = 'banquetOfferCancelled',
  newRestaurantCreated = 'newRestaurantCreated',
}

export const NotificationTypeList = [
  { id: NotificationType.admin, name: NotificationType.admin },
  { id: NotificationType.userRegistered, name: 'Пользователь зарегистрирован' },
  { id: NotificationType.chatMessage, name: 'Новое сообщение в чате' },
  { id: NotificationType.preOrderCreated, name: 'Предзаказа создан' },
  { id: NotificationType.orderCreated, name: 'Заказ создан' },
  { id: NotificationType.orderCancelled, name: 'Заказ отменен' },
  { id: NotificationType.orderPreparing, name: 'Заказ готовится' },
  { id: NotificationType.orderDelivering, name: 'Заказ доставляется' },
  { id: NotificationType.orderDelivered, name: 'Заказ доставлен' },
  { id: NotificationType.orderFeedbackRemind, name: 'Заказ доставлен (отзыв)' },
  { id: NotificationType.authPinCode, name: 'СМС otp' },
  { id: NotificationType.promoCode, name: 'Прмокод' },
  { id: NotificationType.newComplaint, name: 'Новая жалоба' },
  { id: NotificationType.newPromoQrCode, name: 'Новый промокод' },
  {
    id: NotificationType.notifyAboutNewPromoRestaurant,
    name: 'Уведомление о появлении новой акции избранного ресторана',
  },
  { id: NotificationType.promoQrCodeCreated, name: 'Создана бронь' },
  { id: NotificationType.promoQrCodeConfirmation, name: 'Подтверждение брони' },
  { id: NotificationType.promoReserveStartsIn10MinutesVendor, name: 'Бронь начнется через 10 минут' },
  { id: NotificationType.promoReserveExpiresIn15Minutes, name: 'Бронь закончится через 15 минут' },
  { id: NotificationType.promoReserveExpired, name: 'Бронь просрочена' },
  { id: NotificationType.promoReserveExpiredVendor, name: 'Бронь просрочена' },
  { id: NotificationType.promoQrCodeApproved, name: 'Бронь подтверждена' },
  { id: NotificationType.promoQrCodeCancelled, name: 'Бронь отменена' },
  { id: NotificationType.promoReserveCancelledVendor, name: 'Бронь отменена' },
  { id: NotificationType.subscriptionActivated, name: 'Подписка активировалась' },
  { id: NotificationType.banquetCreatedVendor, name: 'Новая заявка на банкет' },
  { id: NotificationType.banquetCreatedAbstractVendor, name: 'Уведомлении о новых банкетах' },
  { id: NotificationType.banquetAddedToFavorites, name: 'Банкет добавлен в избранные' },
  { id: NotificationType.banquetStopped, name: 'Прием заявок на банкет приостановлен' },
  { id: NotificationType.fakeBanquetStoppedVendor, name: 'Прием заявок на фейковый банкет приостановлен' },
  { id: NotificationType.banquetOfferReserved, name: 'Предложение о банкете забронирован' },
  { id: NotificationType.newBanquetOfferSent, name: 'Новое предложение о банкете' },
  { id: NotificationType.banquetOfferReminder, name: 'Напоминание о банкетных предложениях' },
  { id: NotificationType.banquetReserved, name: 'Банкет забронирован' },
  { id: NotificationType.banquetOfferCancelled, name: 'Предложение о банкете отменено' },
  { id: NotificationType.newRestaurantCreated, name: 'Уведомление о появлении нового ресторана' },
]
