import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { AlertForm } from './AlertForm'

interface TitleProps {
  record?: any
}

const Title: FC<TitleProps> = ({ record }) => <span>Новый алерт</span>

const AlertCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <AlertForm isEditMode={false} />
    </Create>
  )
}

export default AlertCreate
