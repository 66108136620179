export enum OrderStatus {
  Received = 'received',
  Waiting = 'waiting',
  OnHold = 'onHold',
  Sending = 'sending',
  Confirmed = 'confirmed',
  Preparing = 'preparing',
  Ready = 'ready',
  Sent = 'sent',
  Assigned = 'assigned',
  Delivering = 'delivering',
  Arrived = 'arrived',
  Delivered = 'delivered',
  Paused = 'paused',
  Canceled = 'canceled',
  Returned = 'returned',
}

export const OrderStatusFinishedList = [
  { id: OrderStatus.Waiting, name: 'Ожидает оплаты' },
  { id: OrderStatus.Received, name: 'Получен' },
  { id: OrderStatus.OnHold, name: 'Приостановлен к отправке' },
  { id: OrderStatus.Sending, name: 'Отправляется Партнеру' },
  { id: OrderStatus.Confirmed, name: 'Принят партнером' },
  { id: OrderStatus.Preparing, name: 'Готовится' },
  { id: OrderStatus.Ready, name: 'Готов к доставке' },
  { id: OrderStatus.Sent, name: 'Отправлен в доставку' },
  { id: OrderStatus.Assigned, name: 'Назначен курьеру' },
  { id: OrderStatus.Delivering, name: 'Доставляется' },
  { id: OrderStatus.Arrived, name: 'На адресе' },
  { id: OrderStatus.Delivered, name: 'Доставлен' },
]

export const OrderStatusUnfinishedList = [
  { id: OrderStatus.Paused, name: 'Приостановлен при доставке' },
  { id: OrderStatus.Delivered, name: 'Доставлен' },
  { id: OrderStatus.Canceled, name: 'Отменен' },
  { id: OrderStatus.Returned, name: 'Возвращен' },
]

export const OrderStatusList = [...OrderStatusFinishedList, ...OrderStatusUnfinishedList]

export const OrderStatusesActive = [OrderStatus.Received, OrderStatus.Sending]

export const OrderStatusesFinished = [
  OrderStatus.Paused,
  OrderStatus.Canceled,
  OrderStatus.Returned,
  OrderStatus.Delivered,
]
