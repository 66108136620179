import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { CancelReasonForm } from './CancelReasonForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  return <span>Новая причина отмены</span>
}

const CancelReasonCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <CancelReasonForm />
    </Create>
  )
}

export default CancelReasonCreate
