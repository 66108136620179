import { create } from 'zustand/react'

export type CancelReason = {
  id: string
  name: string
  selfInput: boolean
}

export type Store = {
  cancelReasons: CancelReason[] | null
  setCancelReasons: (reasons: CancelReason[]) => void
}

export const useCommonStore = create<Store>((set, get) => ({
  cancelReasons: null,
  setCancelReasons: (reasons) => {
    set({
      cancelReasons: reasons,
    })
  },
}))
