export enum CancelReasonUserType {
  OnlyForClient = 'OnlyForClient',
  OnlyForPartner = 'OnlyForPartner',
  ForAggregator = 'ForAggregator',
  ForEveryone = 'ForEveryone',
  ForApi = 'ForApi',
}

export const CancelReasonUserTypeList = [
  { id: CancelReasonUserType.OnlyForClient, name: 'Для клиентов' },
  { id: CancelReasonUserType.OnlyForPartner, name: 'Для партнеров' },
  { id: CancelReasonUserType.ForAggregator, name: 'Для аггрегаторов' },
  { id: CancelReasonUserType.ForApi, name: 'Для API' },
  { id: CancelReasonUserType.ForEveryone, name: 'Для всех' },
]

export const CancelReasonUserTypeListForCreationAndEdit = CancelReasonUserTypeList.filter(
  (el) => el.id !== CancelReasonUserType.ForApi,
)

export enum CancelReasonEntityType {
  DeliveryOrder = 'DeliveryOrder',
  PromoReserve = 'PromoReserve',
}

export const CancelReasonEntityTypeList = [
  { id: CancelReasonEntityType.DeliveryOrder, name: 'Доставка заказ' },
  { id: CancelReasonEntityType.PromoReserve, name: 'Рестораны заказ' },
]
