export enum AdminRole {
  SuperAdmin = 'super_admin',
  AdminPartner = 'adminPartner',
  SupportPartner = 'supportPartner',
  Operator = 'operator',
  ClientManager = 'clientManager',
  ContentManager = 'contentManager',
  ContentUnit = 'contentUnit',
  Finance = 'Finance',

  PartnerAdmin = 'partnerAdmin',
  PartnerUnitAdmin = 'partnerUnitAdmin',
  PartnerContent = 'partnerContent',
  PartnerLineEmployee = 'partnerLineEmployee',
  PartnerBrandAdmin = 'partnerBrandAdmin',
}

export const AggregatorAdminRoles = [
  AdminRole.SuperAdmin,
  AdminRole.AdminPartner,
  AdminRole.SupportPartner,
  AdminRole.Operator,
  AdminRole.ClientManager,
  AdminRole.ContentManager,
  AdminRole.ContentUnit,
  AdminRole.Finance,
]

export const AggregatorAdminRoleList = [
  { id: AdminRole.SuperAdmin, name: 'Супер администратор' },
  { id: AdminRole.AdminPartner, name: 'Администратор Партнеров (Агрегатор)' },
  { id: AdminRole.SupportPartner, name: 'Поддержка Партнера' },
  { id: AdminRole.Operator, name: 'Оператор КЦ ' },
  { id: AdminRole.ClientManager, name: 'Клиентский менеджер' },
  { id: AdminRole.ContentManager, name: 'Контент маркетинг' },
  { id: AdminRole.ContentUnit, name: 'Контент ресторан' },
  { id: AdminRole.Finance, name: 'Фин менеджер' },
]

export const PartnerAdminRoleList = [
  { id: AdminRole.PartnerAdmin, name: 'Администратор партнера' },
  { id: AdminRole.PartnerUnitAdmin, name: 'Администратор заведения' },
  { id: AdminRole.PartnerContent, name: 'Партнер контент менеджер' },
  { id: AdminRole.PartnerLineEmployee, name: 'Линейный сотрудник' },
  { id: AdminRole.PartnerBrandAdmin, name: 'Администратор бренда' },
]

export const AdminRoleList = [...AggregatorAdminRoleList, ...PartnerAdminRoleList]
