import * as React from 'react'
import { FC } from 'react'
import { Filter, FunctionField, List, ListProps, SelectInput, TextField, TextInput, usePermissions } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import PauseIcon from '@mui/icons-material/Pause'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import BrandInput from 'src/components/inputs/BrandInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { UnitPauseModal } from 'src/resources/unit/UnitPauseModal'
import { UnitAddDeliveryTimeModal } from 'src/resources/unit/UnitAddDeliveryTimeModal'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { UnitStatusList } from 'src/types/enum/UnitStatus'
import UnitStatusField from 'src/components/fields/UnitStatusField'
import { useRecordContext } from 'ra-core'
import { DropdownMenu } from 'src/components/DropdownMenu/DropdownMenu'
import { MenuModalButton } from 'src/components/Modal/MenuModalButton'
import ApiIntegrationSyncButton from 'src/resources/api-integration-sync/ApiIntegrationSyncButton'
import { ApiIntegrationSyncType } from 'src/types/enum/ApiIntegrationSyncType'
import RegionInput from '../../components/inputs/ReqionInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Unit',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput
        source="name:$contL&brand.name:$contL"
        label={'Поиск...'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
      <SelectInput source="status" label={'Статус'} choices={UnitStatusList} />
      <IfCanAccess label={'Партнер'} source={'partnerId'} alwaysOn aggregator action={'show'}>
        <PartnerInput source={'partnerId'} label={'Партнер'} />
      </IfCanAccess>
      <IfCanAccess source={'region'} label={'Регион'} aggregator action={'list'}>
        <RegionInput source={'regionId'} label={'Регион'} />
      </IfCanAccess>
      <BrandInput label={'Бренд'} alwaysOn={true} resettable={true} />
    </Filter>
  )
}
const ActionFields = () => {
  const record = useRecordContext()
  return (
    <DropdownMenu>
      <MenuModalButton
        label={'Пауза'}
        type={'iconButton'}
        icon={<PauseIcon />}
        key={'import'}
        modal={<UnitPauseModal resource={'unit'} />}
      >
        Пауза
      </MenuModalButton>
      <MenuModalButton
        label={'Время доставки'}
        type={'iconButton'}
        icon={<MoreTimeIcon />}
        key={'import'}
        modal={<UnitAddDeliveryTimeModal resource={'unit'} />}
      >
        Время доставки
      </MenuModalButton>
      <ApiIntegrationSyncButton buttonView={'menuItem'} syncType={ApiIntegrationSyncType.StopList} unitId={record.id} />
      <ApiIntegrationSyncButton buttonView={'menuItem'} syncType={ApiIntegrationSyncType.Menu} unitId={record.id} />
      {/*<ApiIntegrationSyncButton
        buttonView={'menuItem'}
        syncType={ApiIntegrationSyncType.StopList}
        repeatable={true}
        unitId={record.id}
      />
      <ApiIntegrationSyncButton
        buttonView={'menuItem'}
        syncType={ApiIntegrationSyncType.Menu}
        repeatable={true}
        unitId={record.id}
      />*/}
    </DropdownMenu>
  )
}
const UnitList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      exporter={false}
      filters={<_Filter />}
      title="Заведения"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <FunctionField source={'innerName'} label={'Название'} render={(order: any) => order.innerName ?? order.name} />
        <TextField source={'address'} label={'Адрес'} />
        <TextField source={'region.name'} label={'Регион'} />
        <TextField source="brand.name" label={'Бренд'} />
        <IfCanAccess aggregator action={'show'} label={'Партнер'}>
          <TextField source="partner.name" label={'Партнер'} />
        </IfCanAccess>
        <UnitStatusField source={'status'} label={'Статус'} />
        <ActionFields />
      </Datagrid>
    </List>
  )
}

export default UnitList
