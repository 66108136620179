import * as React from 'react'
import * as _ from 'lodash'
import {
  SimpleForm,
  TextInput,
  required,
  SimpleFormIterator,
  NumberInput,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  DateTimeInput,
} from 'react-admin'
import { TariffType, TariffTypeList } from 'src/types/enum/TariffType'
import { TariffDescriptionValueTypeList } from 'src/types/enum/TariffDescriptionValueType'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import PromoServiceCoffeeInput from 'src/components/inputs/PromoServiceCoffeeInput'
import PromoServiceRestaurantsInput from 'src/components/inputs/PromoServiceRestaurantsInput'
import { RecordChanges, RecordType } from 'src/components/RecordChangeList'

const TariffForm = (props: any) => {
  const isCreation = props.isCreation || false
  const promoInputHelperText = isCreation
    ? 'Если ниодна акция не выбрана - тариф распространяется на все акции. Если выбрать несколько акций - автоматически будут созданы тарифы для каждой выбранной акции отдельно'
    : 'Если акция не выбрана - тариф распространяется на все акции'

  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput
        source={'name'}
        label={'Название Тарифа'}
        validate={required()}
        variant={'outlined'}
        fullWidth={true}
      />
      <SelectInput
        source={'type'}
        label={'Тип'}
        choices={TariffTypeList}
        validate={required()}
        variant={'outlined'}
        fullWidth={true}
      />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === TariffType.DeliveryOrder && (
            <PromoServiceDeliveryInput
              resettable={true}
              source={isCreation ? 'promoIds' : 'promoId'}
              label={'Акция доставки'}
              multiple={isCreation}
              fullWidth
              helperText={promoInputHelperText}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === TariffType.RestaurantOrder && (
            <PromoServiceRestaurantsInput
              resettable={true}
              source={isCreation ? 'promoIds' : 'promoId'}
              label={'Акция Ресторана'}
              multiple={isCreation}
              fullWidth
              helperText={promoInputHelperText}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === TariffType.CoffeeOrder && (
            <PromoServiceCoffeeInput
              resettable={true}
              source={isCreation ? 'promoIds' : 'promoId'}
              label={'Акция Кофейни'}
              multiple={isCreation}
              fullWidth
              helperText={promoInputHelperText}
            />
          )
        }
      </FormDataConsumer>
      <PartnerInput
        resettable
        source={'partnerId'}
        label={'Партнер'}
        fullWidth
        helperText={'Если партнер не выбран - тариф распространяется на все компании'}
      />
      <DateTimeInput
        source={'startAt'}
        label={'Начало действия'}
        variant={'outlined'}
        fullWidth={false}
        sx={{ mt: 3 }}
      />
      <DateTimeInput
        source={'endAt'}
        label={'Окончание действия'}
        variant={'outlined'}
        fullWidth={false}
        sx={{ m: 0 }}
      />
      <ArrayInput source="descriptions" label={'Настройки'} sx={{ mt: 1 }}>
        <SimpleFormIterator inline>
          <NumberInput source={'minAmount'} label={'От'} helperText={false} fullWidth={false} validate={required()} />
          <NumberInput source={'maxAmount'} label={'До'} helperText={false} fullWidth={false} validate={required()} />
          <SelectInput
            source={'commissionType'}
            label={'Тип коммисии'}
            choices={TariffDescriptionValueTypeList}
            helperText={false}
            fullWidth={false}
          />
          <NumberInput
            source={'commission'}
            label={'Коммисия'}
            helperText={false}
            fullWidth={false}
            validate={required()}
          />
          <NumberInput
            source={'serviceFee'}
            label={'Сервисный сбор'}
            helperText={false}
            fullWidth={false}
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
      {!isCreation && (
        <RecordChanges
          recordType={RecordType.Tariff}
          additionalMap={[
            { key: 'type', label: 'Тип', choices: TariffTypeList },
            { key: 'startAt', label: 'Начало действия' },
            { key: 'endAt', label: 'Окончание действия' },
            {
              key: 'descriptions',
              label: 'Настройки',
              valueBuilder: (descriptions) =>
                descriptions
                  .map((value: any, idx: number) => {
                    let valueString = idx === 0 ? '1 ->' : `\n${idx + 1} ->`

                    if (_.isNumber(value.minAmount)) {
                      valueString += ` От: ${value.minAmount},`
                    }
                    if (_.isNumber(value.maxAmount)) {
                      valueString += ` До: ${value.maxAmount},`
                    }
                    if (_.isNumber(value.commissionType)) {
                      valueString += ` Тип комиссии: ${TariffDescriptionValueTypeList.find((el) => el.id === value.commissionType)?.name},`
                    }
                    if (_.isNumber(value.commission)) {
                      valueString += ` Комиссия: ${value.commission},`
                    }
                    if (_.isNumber(value.serviceFee)) {
                      valueString += ` Сервисный сбор: ${value.serviceFee},`
                    }
                    return valueString.substring(0, valueString.length - 1)
                  })
                  .join() || 'Пусто',
            },
          ]}
        />
      )}
    </SimpleForm>
  )
}
export default TariffForm
