import * as React from 'react'
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  validate?: any
  fullWidth?: boolean
  alwaysOn?: boolean
  filter?: any
  optionText?: (value: any) => string
  onChange?: (ids: number[]) => void
}

const PromoUnitGroupArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      alwaysOn={props.alwaysOn}
      allowEmpty={false}
      source={props.source}
      reference="promo-unit-group"
      variant={'outlined'}
      fullWidth={true}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      enableGetChoices={({ q }) => q && q.length >= 2}
      perPage={100}
    >
      <AutocompleteArrayInput
        validate={props.validate}
        label={props.label}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) => `${i?.name || ''}`}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        disableCloseOnSelect
      />
    </ReferenceArrayInput>
  )
}

export default PromoUnitGroupArrayInput
