import * as React from 'react'
import {
  RecordContextProvider,
  useDataProvider,
  useRefresh,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  required,
  useNotify,
  NumberInput,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { PromoPayerList } from 'src/types/enum/PromoPayer'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}

export const OrderRefundCreateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const notify = useNotify()
  const handleSubmit = async (data: FieldValues) => {
    try {
      await dataProvider.create(`order/${record.id}/refundPayment`, { data })
      refresh()

      if (props.onClose) {
        props.onClose()
      }
    } catch (e: any) {
      notify(e.message.toString(), { type: 'error' })
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        resource={'area'}
        title={`Возрат по заказу № ${record.number}`}
        onClose={props.onClose}
        saveButtonLabel={'Подтвердить'}
        save={handleSubmit}
      >
        <BooleanInput source="partialRefund" label={'Частичный возврат'} />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.partialRefund && <NumberInput source="refundSum" label={'Сумма возрата'} validate={[required()]} />
          }
        </FormDataConsumer>
        <SelectInput source="payer" label={'За чей счет возврат'} choices={PromoPayerList} validate={[required()]} />
      </ModalForm>
    </RecordContextProvider>
  )
}
