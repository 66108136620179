import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  required,
  FormDataConsumer,
  useCreate,
  useUpdate,
  useGetIdentity,
} from 'react-admin'
import { ChangeEventHandler, useMemo } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import * as _ from 'lodash'
import { AdminRole, AdminRoleList } from 'src/types/enum/AdminRole'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import UnitArrayInput from 'src/components/inputs/UnitArrayInput'
import RestaurantAllArrayInput from 'src/components/inputs/RestaurantAllArrayInput'
import PartnerArrayInput from 'src/components/inputs/PartnerArrayInput'
import { useRecordContext } from 'ra-core'
import { useNavigate } from 'react-router-dom'
import { USER_DATA_STORAGE_KEY } from 'src/common/providers/AuthProvider'

const InfoTab = (props: any) => {
  const form = useFormContext()

  const acceptedRolesForSelectingPartners = useMemo(
    () => [
      AdminRole.AdminPartner,
      AdminRole.SupportPartner,
      AdminRole.PartnerAdmin,
      AdminRole.PartnerUnitAdmin,
      AdminRole.PartnerContent,
      AdminRole.PartnerLineEmployee,
      AdminRole.PartnerBrandAdmin,
    ],
    [],
  )
  const acceptedRolesForSelectingRestaurantsAndUnits = useMemo(
    () => [
      AdminRole.PartnerAdmin,
      AdminRole.PartnerUnitAdmin,
      AdminRole.PartnerContent,
      AdminRole.PartnerLineEmployee,
      AdminRole.PartnerBrandAdmin,
    ],
    [],
  )

  const handleRoleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const role = e.target.value as AdminRole

    if (!acceptedRolesForSelectingRestaurantsAndUnits.includes(role)) {
      form.setValue('unitsIds', [])
      form.setValue('restaurantsIds', [])
    }
    if (!acceptedRolesForSelectingPartners.includes(role)) {
      form.setValue('partnerId', null)
      form.setValue('unitsIds', null)
      form.setValue('restaurantsIds', null)
    }
  }

  return (
    <FormTab label="Информация" path={'benefit'} {...props}>
      <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoleList}
        fullWidth
        variant={'outlined'}
        onChange={(e) => handleRoleChange(e)}
        validate={required()}
      />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          acceptedRolesForSelectingPartners.includes(formData.role) && (
            <PartnerArrayInput source="partnersIds" label={'Партнеры'} fullWidth />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          acceptedRolesForSelectingPartners.includes(formData.role) &&
          acceptedRolesForSelectingRestaurantsAndUnits.includes(formData.role) && (
            <RestaurantAllArrayInput
              source="restaurantsIds"
              label={'Рестораны'}
              fullWidth
              filter={{
                'partnerId:$in':
                  !_.isArray(formData.partnersIds) || _.isEmpty(formData.partnersIds) ? [0] : formData.partnersIds,
              }}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          acceptedRolesForSelectingPartners.includes(formData.role) &&
          acceptedRolesForSelectingRestaurantsAndUnits.includes(formData.role) && (
            <UnitArrayInput
              source="unitsIds"
              label={'Заведения доставки'}
              fullWidth
              filter={{
                'partnerId:$in':
                  !_.isArray(formData.partnersIds) || _.isEmpty(formData.partnersIds) ? [0] : formData.partnersIds,
              }}
            />
          )
        }
      </FormDataConsumer>

      <TextInput source="email" label={'Email'} fullWidth variant={'outlined'} validate={required()} />
      <TextInput source="name" label={'Имя'} fullWidth variant={'outlined'} />

      <TextInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth />
      <IfCanAccess aggregator action={'edit'}>
        <TextInput
          source="settings.callerId"
          label={'Caller ID'}
          helperText={'ID оператора в телефонии'}
          variant={'outlined'}
          fullWidth
        />
      </IfCanAccess>
    </FormTab>
  )
}

const AdminForm = (props: any) => {
  const { identity, refetch } = useGetIdentity()
  const record = useRecordContext()
  const [create] = useCreate()
  const [update] = useUpdate()
  const navigate = useNavigate()

  const postSave = async (data: FieldValues) => {
    if (props.isCreation) {
      await create('admin', { data })
    } else {
      await update('admin', { id: record.id, previousData: record, data })
      //
      if (record.id === identity?.id) {
        localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify({ id: record.id, name: data?.name || record.name }))

        if (refetch) {
          await refetch()
        }
      }
    }
    navigate(-1)
  }

  return (
    <TabbedForm {...props} redirect={'list'} onSubmit={postSave}>
      <InfoTab />
    </TabbedForm>
  )
}

export default AdminForm
