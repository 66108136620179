export enum PaymentStatus {
  Wait = 'wait',
  InvoiceCreated = 'invoice_created',
  Error = 'error',
  Paid = 'paid',
  Refund = 'refund',
  Refunded = 'refunded',
  Hold = 'hold',
  Canceled = 'canceled',
  RejectRegistered = 'reject_registered',
  ConfirmRegistered = 'confirm_registered',
  RefundRegistered = 'refund_registered',
  RefundError = 'refund_error',
  Sent = 'sent',
}
export const PaymentStatusList = [
  { id: PaymentStatus.Wait, name: 'Ожидает оплаты' },
  { id: PaymentStatus.InvoiceCreated, name: 'Счет создан' },
  { id: PaymentStatus.Error, name: 'Ошибка' },
  { id: PaymentStatus.Paid, name: 'Оплачено' },
  { id: PaymentStatus.Refund, name: 'Возвращается' },
  { id: PaymentStatus.Refunded, name: 'Возвращен' },
  { id: PaymentStatus.Hold, name: 'Холд' },
  { id: PaymentStatus.Canceled, name: 'Отменен' },
  { id: PaymentStatus.RejectRegistered, name: 'Отказ зарегистрирован' },
  { id: PaymentStatus.ConfirmRegistered, name: 'Подвтреждение зарегистрировано' },
  { id: PaymentStatus.RefundRegistered, name: 'Возврат зарегистрирован' },
  { id: PaymentStatus.RefundError, name: 'Ошибка возврата' },
  { id: PaymentStatus.Sent, name: 'Отправлен в доставку' },
]
