import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import AssetField from 'src/components/fields/AssetField'
import UnitInput from 'src/components/inputs/UnitInput'
import { Datagrid } from 'src/components/list/Datagrid'
import BrandLinkField from 'src/components/fields/BrandLinkField'
import UnitLinkField from 'src/components/fields/UnitLinkField'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Product',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <UnitInput alwaysOn={true} resettable={true} />
    </Filter>
  )
}

const ProductList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Каталог товаров"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <AssetField source={'image'} label={''} />
        <TextField source={'name'} label={'Название'} />
        <BrandLinkField mainSource={'brand'} label={'Бренд'} />
        <UnitLinkField label={'Заведение'} />
      </Datagrid>
    </List>
  )
}

export default ProductList
