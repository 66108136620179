import PersonIcon from '@mui/icons-material/Person'
import AlertList from './AlertList'
import AlertEdit from './AlertEdit'
import AlertCreate from './AlertCreate'

export default {
  create: AlertCreate,
  edit: AlertEdit,
  list: AlertList,
  icon: PersonIcon,
}
