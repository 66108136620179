import { AutocompleteInput, ReferenceInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  validate?: any
  fullWidth?: boolean
  onChange?: (val: any) => void
  emptyText?: string
}
const RegionInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source ?? 'regionId'}
      reference="region"
      resettable={props.resettable}
      filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$eq': searchText } : {}) })}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
    >
      <AutocompleteInput
        optionText={(i) => `${i?.name || 'вывыв'}`}
        label={props.label ?? 'Регион'}
        emptyText={props.emptyText}
        validate={props.validate}
        fullWidth
      />
    </ReferenceInput>
  )
}

export default RegionInput
