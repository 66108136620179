import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  displayPartner?: boolean
}

const BrandArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      allowEmpty={false}
      source={props.source}
      reference="brand"
      variant={'outlined'}
      fullWidth={true}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label={props.label}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) =>
          props.displayPartner ? (i ? `${i?.name} - ${i?.partner?.name}` : '') : i ? `${i?.name}` : ''
        }
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </ReferenceArrayInput>
  )
}

export default BrandArrayInput
