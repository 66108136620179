import ListIcon from '@mui/icons-material/List'
import CancelReasonList from './CancelReasonList'
import CancelReasonEdit from './CancelReasonEdit'
import CancelReasonCreate from './CancelReasonCreate'

export default {
  create: CancelReasonCreate,
  edit: CancelReasonEdit,
  list: CancelReasonList,
  icon: ListIcon,
}
