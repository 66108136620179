import * as React from 'react'
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, NumberInput, SelectInput, required } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { ProductDeliveryType, ProductDeliveryTypeList } from 'src/types/enum/ProductDeliveryType'
import UnitInput from 'src/components/inputs/UnitInput'
import Formatter from 'src/utils/formatters'
import ProductCategoryInput from 'src/components/inputs/ProductCategoryInput'
import BrandInput from 'src/components/inputs/BrandInput'

const Form = (props: any) => {
  const form = useFormContext()
  const values = form.watch()

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }

  const handleBrandChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    form.setValue('unitId', '')
  }

  return (
    <>
      <BooleanInput source="published" label={'Активен'} fullWidth />
      <BooleanInput source="isAvailable" label={'Доступен для заказа'} fullWidth />
      <BrandInput
        source={'brandId'}
        label={'Бренд'}
        resettable
        validate={[required()]}
        onChange={handleBrandChange}
        fullWidth
      />
      <UnitInput
        source={'unitId'}
        label={'Заведение'}
        filter={{ brandId: values.brandId || 0 }}
        resettable
        validate={[required()]}
        fullWidth
      />
      <TextInput source={'name'} label="Название" onChange={handleNameChange} validate={[required()]} />
      <TextInput source={'slug'} label="URI" />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.unitId && (
            <ProductCategoryInput
              source="categoryId"
              label={'Категория меню'}
              unitId={formData.unitId}
              resettable={false}
              fullWidth
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <MediaInput source={'image'} label={'Изображение'} />
      <TextInput source={'description'} label="Описание" multiline={true} />
      <BooleanInput source={'isWeighted'} label="Товар весовой" />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => (
          <NumberInput
            source={'price'}
            label={formData.isWeighted ? 'Цена за 100гр' : 'Цена'}
            validate={[required()]}
          />
        )}
      </FormDataConsumer>
      <TextInput source={'vendorCode'} label="Код товара" />
      <TextInput source={'weight'} label="Вес" helperText={'Вес в граммах вида 100/50/30'} />
      <NumberInput source={'energy'} label="Энергетическая ценность (Ккал)" />
      <NumberInput
        source={'volume'}
        label="Обьем (для напитков)"
        helperText={'Объём жидкости для напитков, значение должно быть в мл'}
      />
      <NumberInput source={'vat'} label="Ставка НДС" helperText={'(0, 10, 20)'} />
      <SelectInput
        source={'deliveryType'}
        label="Тип доставки"
        choices={ProductDeliveryTypeList}
        validate={[required()]}
      />
    </>
  )
}
const ProductForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ published: true, deliveryType: ProductDeliveryType.All }}>
      <Form />
    </SimpleForm>
  )
}
export default ProductForm
