import * as React from 'react'
import { FC } from 'react'
import {
  ResourceContextProvider,
  ListContextProvider,
  ListProps,
  TextField,
  Pagination,
  SelectField,
  DateField,
  Filter,
  ListToolbar,
} from 'react-admin'
import { Card } from '@mui/material'
import { useListController } from 'src/components/list/useListController'
import Grid from '@mui/material/Grid'
import CardWithIcon from 'src/components/CardWithIcon'
import Formatter from 'src/utils/formatters'
import { OrderStatusList } from 'src/types/enum/OrderStatus'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import UnitInput from 'src/components/inputs/UnitInput'
import BrandInput from 'src/components/inputs/BrandInput'
import PriceField from 'src/components/fields/PriceField'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import { DeliveryByList } from 'src/types/enum/DeliveryBy'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { Datagrid } from 'src/components/list/Datagrid'

interface Props {
  pageInfo: {
    total: string
    sum_total_discount: number
    sum_partner_discount: number
    sum_aggregator_discount: number
    sum_total: number
    sum_total_from_client: number
    sum_commission: number
    sum_penalty_sum: number
    sum_refunds_total: number
    sum_refunds_aggregator: number
    sum_refunds_partner: number
    sum_totalsurcharges: number
    sum_total_to_partner: number
    sum_payment_sum: number
  }
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
      <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'show'} alwaysOn={true}>
        <PartnerInput source={'partnerId'} label={'Партнер'} alwaysOn={true} />
      </IfCanAccess>

      <BrandInput source={'brandId'} label={'Бренд'} alwaysOn={true} />
      <UnitInput source={'unitId'} label={'Заведение'} alwaysOn={true} />
    </Filter>
  )
}

const Details = (props: Props) => {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={2} sm={1.5} md={1.5}>
        <CardWithIcon title={'Сумма Скидок'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_total_discount)}`} />
      </Grid>
      <Grid item xs={1} sm={1.5} md={1.5}>
        <CardWithIcon
          title={'Скидки за счет агрегатора'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_aggregator_discount)}`}
        />
      </Grid>
      <Grid item xs={1} sm={1.5} md={1.5}>
        <CardWithIcon
          title={'Скидки за счет партнера'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_partner_discount)}`}
        />
      </Grid>
      <Grid item xs={2} sm={2.5} md={2.5}>
        <CardWithIcon
          title={'Оплата по заказу (по транзакциям)'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_total)}`}
        />
      </Grid>
      <Grid item xs={2} sm={2.5} md={2.5}>
        <CardWithIcon
          title={'Поступило денег от покупателя'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_total_from_client)}`}
        />
      </Grid>
      <Grid item xs={2} sm={2.5} md={2.5}>
        <CardWithIcon title={'Коммисии'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_commission)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Штрафы'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_penalty_sum)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Возвраты'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_refunds_total)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon
          title={'Возвраты за счет агрегатора'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_refunds_aggregator)}`}
        />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon
          title={'Возвраты за счет партнера'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_refunds_partner)}`}
        />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Доплаты'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_totalsurcharges)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon
          title={'Итого за заказы'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_total_to_partner)}`}
        />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Заказов'} subtitle={`${parseInt(props.pageInfo.total, 10)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon
          title={'Сумма поступлений от эквайринга'}
          subtitle={`${Formatter.formatPrice(props.pageInfo.sum_payment_sum)}`}
        />
      </Grid>
    </Grid>
  )
}

const ReportOrdersList: FC<ListProps> = () => {
  const listController = useListController<any>({
    resource: 'reports/orders',
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <ResourceContextProvider value={'reports/orders'}>
      <ListContextProvider value={listController as any}>
        <ListToolbar filters={<_Filter />} title={''} />
        {listController.pageInfo && <Details pageInfo={listController.pageInfo} />}
        <Card>
          <Datagrid bulkActionButtons={false} rowClick={(id) => `/order/${id}/show`}>
            <TextField source={'number'} label={'№ заказа'} sx={{ whiteSpace: 'nowrap' }} />
            <DateField source={'confirmedAt'} label={'Время принятия'} showTime />
            <DateField source={'refunded_at'} label={'Время возврата'} showTime />
            <IfCanAccess aggregator action={'show'} label={'Партнер'}>
              <TextField source={'partner_name'} label={'Партнер'} />
            </IfCanAccess>
            <TextField source={'partnerNumber'} label={'№ заказа партнера'} />
            <SelectField source={'status'} label={'Статус'} choices={OrderStatusList} />
            <SelectField source={'paymentMethod'} label={'Тип оплаты'} choices={PaymentMethodList} />
            <TextField source={'brand_name'} label={'Бренд'} sortable={false} />
            <TextField source={'unit_address'} label={'Адрес точки'} sortable={false} />
            <SelectField source={'deliveryBy'} label={'Тип доставки'} choices={DeliveryByList} />
            <PriceField source={'total_base'} label={'GROSS'} sortable={false} />
            <PriceField source={'totalDiscount'} label={'Скидка'} sortable={false} />
            <PriceField source={'partner_discount'} label={'Скидка за счет партнера'} sortable={false} />
            <PriceField source={'aggregator_discount'} label={'Скидка за счет ресторана'} sortable={false} />
            <TextField source={'promo_name'} label={'Вид Скидки'} sortable={false} />
            <PriceField source={'total'} label={'Оплата по заказу (по транзакциям)'} sortable={false} />
            <PriceField source={'total_from_client'} label={'Поступило денег от покупателя'} sortable={false} />
            <PriceField source={'commission'} label={'Коммисия'} sortable={false} />
            <PriceField source={'penalty_sum'} label={'Штрафы'} sortable={false} />
            <PriceField label={'Возвраты'} source={'refunds_total'} sortable={false} />
            <PriceField label={'Возвраты за счет агрегатора'} source={'refunds_aggregator'} sortable={false} />
            <PriceField label={'Возвраты за счет партнера'} source={'refunds_partner'} sortable={false} />
            <PriceField source={'totalSurcharges'} label={'Доплаты'} sortable={false} />
            <PriceField source={'total_to_partner'} label={'Итого за заказ'} sortable={false} />
            <PriceField source={'payment_sum'} label={'Сумма поступлений от эквайринга'} sortable={false} />
          </Datagrid>
          <Pagination />
        </Card>
      </ListContextProvider>
    </ResourceContextProvider>
  )
}

export default ReportOrdersList
