import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import { CustomEdit } from 'src/components/CustomEdit'
import { CancelReasonForm } from './CancelReasonForm'

const CancelReasonEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect={'list'} mutationMode="pessimistic">
      <CancelReasonForm />
    </CustomEdit>
  )
}
export default CancelReasonEdit
